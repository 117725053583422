import React from 'react'
import Image from 'next/image'
import moment from 'moment'

import { ConditionalWrapper, CustomImage, Icon, TextLink } from '@/components'
import {
  MAIN_ADDRESS,
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  MAIN_ADDRESS_LINK,
} from '@/constants'
import { useLanguages } from '@/hooks'
import { generatePath } from '@/apis'
import { homePath } from '@/utils'

import { MainLinks, RightElements, Socials } from './constants'

import logo from '@/assets/images/logo-enterprise.png'

export const AppFooter: React.FC = () => {
  const { t, appLang } = useLanguages()

  return (
    <footer>
      <div id="portal" />
      <section className="footer">
        <div className="footer-left">
          <div className="footer-left-menu">
            <div className="footer-left-menu-item">
              <Icon name="footer-location" />
              <TextLink
                defaultAction
                target="_blank"
                href={MAIN_ADDRESS_LINK}
                className="footer-left-menu"
              >
                {MAIN_ADDRESS}
              </TextLink>
            </div>
            <div className="footer-left-menu-item">
              <Icon name="footer-phone" />
              <TextLink
                defaultAction
                href={`tel:${SUPPORT_PHONE}`}
                className="footer-left-menu"
              >
                {SUPPORT_PHONE}
              </TextLink>
            </div>
            <div className="footer-left-menu-item">
              <Icon name="footer-email" />
              <TextLink
                defaultAction
                className="footer-left-menu"
                href={`mailto:${SUPPORT_EMAIL}`}
              >
                {SUPPORT_EMAIL}
              </TextLink>
            </div>
          </div>
          <div className="footer-social-menu">
            {Socials.map((item, idx) => (
              <div key={idx} className="footer-social-menu-item">
                <TextLink
                  defaultAction
                  target="_blank"
                  // @ts-ignore
                  href={t(item.link)}
                >
                  <Image alt="" src={item.icon} />
                </TextLink>
              </div>
            ))}
          </div>
          <div className="footer-logo">
            <TextLink href={homePath}>
              <CustomImage
                priority
                width={240}
                height={50.71}
                src={logo.src}
                className="w-100"
                style={{ objectFit: 'contain' }}
              />
            </TextLink>
          </div>
        </div>
        <div className="footer-right">
          {RightElements.map((element, idx) => (
            <div key={idx} className="footer-right-box">
              <ConditionalWrapper
                WrapEl={element.WrapEl}
                className={element.className}
              >
                {element.title && (
                  <p className="footer-right-menu-title">{t(element.title)}</p>
                )}
                {element.categories?.map((category, idx) => (
                  <TextLink
                    key={idx}
                    defaultAction={category.isDefault}
                    className="footer-right-menu-title"
                    href={generatePath(category.path, { lang: appLang })}
                  >
                    {t(category.label)}
                  </TextLink>
                ))}
                {!!element?.routes?.length && (
                  <div className="footer-right-menu">
                    {element?.routes
                      ?.filter(_v => _v.hideIn !== appLang)
                      ?.map((route, _idx) => (
                        <TextLink
                          key={_idx}
                          defaultAction={route.isDefault}
                          className="footer-right-menu-item"
                          href={generatePath(route.path, { lang: appLang })}
                        >
                          {t(route.label)}
                          <Icon name="chevron-right" />
                        </TextLink>
                      ))}
                  </div>
                )}
              </ConditionalWrapper>
            </div>
          ))}
        </div>
      </section>
      <div className="footer-divider">
        <div className="divider"></div>
      </div>
      <section className="footer-terms">
        {MainLinks.map((link, idx) => (
          <TextLink key={idx} href={link.to} className="footer-terms-item">
            {t(link.label)} |
          </TextLink>
        ))}
        <p className="footer-terms-item">
          {t('company', { year: moment().get('years') })}
        </p>
      </section>
    </footer>
  )
}
